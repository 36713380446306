import React from "react";
import get from "lodash/get";
import ProgressDots from "../ProgressDots";
import { locationOptions } from "../LocationsPage/CityList";
import { Button } from "react-bootstrap";
import * as s from "./AddOns.module.scss";
import LeftArrow from "../../images/design-components/Left.svg";
import Checkbox from "../../images/design-components/Checkbox_1.svg";
import CheckboxChecked from "../../images/design-components/Checkbox_2.svg";
import cx from "classnames";
import { memberPortalUrl } from "../../../config";
import i18n from "../../utils/i18n";

const isAdditionalLocationAddressesEnabled = false;

const VirtualOfficeAddOns = ({
  selectedAddOns = [],
  setSelectedAddons = () => null,
  next = () => null,
  back = () => null,
  addOns,
  plan,
  billing,
  office,
}) => {
  const toggle = (addon) => {
    const exists = selectedAddOns.find((a) => a._id === addon._id);
    if (exists) {
      const tempAddons = selectedAddOns.filter((a) => a._id !== addon._id);
      setSelectedAddons(tempAddons);
    } else {
      setSelectedAddons([...selectedAddOns, addon]);
    }
  };

  const additionalLocationAddresses = addOns.filter(
    ({ isAdditionLocationAddress }) => isAdditionLocationAddress,
  );

  const digitalReceptionist = addOns.find(
    (a) => a.title === "Digital Receptionist",
  );

  const sortedAddons = addOns.filter(
    (a) => !a.isAdditionLocationAddress && a.title !== "Digital Receptionist",
  );

  if (digitalReceptionist) {
    sortedAddons.push({
      ...digitalReceptionist,
      isDigitalReceptionist: true,
    });
  }

  return (
    <div>
      <div className="container-xxl">
        <div className={`row`}>
          <div className={`col-lg-7 col-md-6 ${s.addOnsColumn}`}>
            <div className={s.addOnsColumn__backButton}>
              <button className={`btn-link`} onClick={back}>
                <span>
                  <img alt="" src={LeftArrow} />
                </span>
                <p className={`fw-bold d-inline ms-3`}>
                  {i18n.t("button.Back")}
                </p>
              </button>
            </div>
            <h2 className={s.addOnsColumn__title}>
              {i18n.t("pickYourAddons")}
            </h2>
            <div className={`row mb-5`}>
              <div className={`col-lg-5 mb-3 mb-lg-0`}>
                <h6 className={s.addOnsColumn__location}>
                  {get(office, "address", "")}
                  <br />
                  {get(office, "city", "")} {get(office, "zip", "")}
                </h6>
              </div>
              <div className={`col-lg-7 col-12`}>
                <Button
                  onClick={() => window.open(memberPortalUrl, "_blank").focus()}
                  className={cx(`btn-flex w-100`)}
                  variant="info"
                >
                  <span>{i18n.t("AlreadyMember")}</span>
                </Button>
              </div>
            </div>

            {addOns.length === 0 && <ProgressDots />}

            {sortedAddons.map((addon) => {
              const {
                title,
                description,
                price,
                _id,
                isDigitalReceptionist = false,
              } = addon;
              return (
                <div
                  key={_id}
                  className={cx({
                    [s.addOnsColumn__item]: true,
                    "cursor-pointer": !isDigitalReceptionist,
                    "overlap-bottom": true,
                  })}
                  onClick={() => {
                    if (isDigitalReceptionist) return;
                    toggle(addon);
                  }}
                  role={"presentation"}
                >
                  <div className={s.addOnsColumn__item__header}>
                    <p className={`fw-bold`}>{title}</p>
                    {!isDigitalReceptionist && (
                      <img
                        alt={""}
                        src={
                          !selectedAddOns.find((a) => a._id === _id)
                            ? Checkbox
                            : CheckboxChecked
                        }
                      />
                    )}
                    <p className={`fw-bold`}>
                      ${price} /{i18n.t("month")}
                    </p>
                  </div>
                  <div className={s.addOnsColumn__item__header2}>
                    <img
                      alt={""}
                      src={
                        !selectedAddOns.find((a) => a._id === _id)
                          ? Checkbox
                          : CheckboxChecked
                      }
                    />
                    <p className={`fw-bold`}>
                      ${price} /{i18n.t("month")}
                    </p>
                  </div>
                  <div className={s.addOnsColumn__item__description}>
                    <p>{description}</p>
                  </div>
                </div>
              );
            })}

            {/* Disabled this section as requested by iQ on 20th Jan 2023*/}
            {isAdditionalLocationAddressesEnabled &&
              additionalLocationAddresses &&
              additionalLocationAddresses.length > 0 && (
                <div className={s.addOnsColumn__item}>
                  <div
                    className={s.addOnsColumn__item__headerAdditionalLocations}
                  >
                    <p className={`fw-bold`}>
                      {i18n.t("voPricing.additionalLocationAddress")}
                    </p>
                  </div>
                  <div className={s.addOnsColumn__item__additionalLocations}>
                    {locationOptions.map((location) => {
                      const locationAddons = additionalLocationAddresses.filter(
                        ({ title }) => title === location.name,
                      );
                      if (!locationAddons || locationAddons.length === 0)
                        return null;
                      return (
                        <div
                          className={
                            s.addOnsColumn__item__additionalLocations__location
                          }
                          key={location.name}
                        >
                          <p className={`fw-bold`}>{location.name}</p>
                          {locationAddons.map((addon) => (
                            <div
                              key={addon._id}
                              onClick={() => toggle(addon)}
                              role={"presentation"}
                              className={"cursor-pointer"}
                            >
                              <img
                                alt=""
                                src={
                                  !selectedAddOns.find(
                                    (a) => a._id === addon._id,
                                  )
                                    ? Checkbox
                                    : CheckboxChecked
                                }
                              />
                              <p className={`mb-0`}>{addon.description}</p>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
          </div>
          <div className={`col-lg-5 col-md-6 ${s.summaryColumn}`}>
            <div>
              <h5 className={s.summaryColumn__title}>
                {i18n.t("voPricing.Summary")}
              </h5>
              <p className={`mb-4 fw-bold`}>{i18n.t("IncludedAddons")}</p>

              <ul className={"list-unstyled"}>
                <li className={"d-flex w-100 justify-content-between"}>
                  <p>{get(plan, "name", "Virtual Offices")}</p>
                  <p>
                    ${get(plan, "price", 0)} /{i18n.t("month")}
                  </p>
                </li>
                {selectedAddOns
                  .filter((a) => !a.isAdditionLocationAddress)
                  .map((addon) => {
                    const { title, price, _id } = addon;
                    return (
                      <li
                        key={_id}
                        className={"d-flex w-100 justify-content-between"}
                      >
                        <p>{title}</p>
                        <p>
                          ${price.toFixed(2)} /{i18n.t("month")}
                        </p>
                      </li>
                    );
                  })}
                {selectedAddOns.filter((a) => a.isAdditionLocationAddress)
                  .length > 0 ? (
                  <li>
                    <p>Additional Location Address</p>
                    <ul className={"list-unstyled ps-2 w-100"}>
                      {selectedAddOns
                        .filter((a) => a.isAdditionLocationAddress)
                        .map((addon) => {
                          const { title, description, price, _id } = addon;
                          return (
                            <li
                              key={_id}
                              className={"d-flex w-100 justify-content-between"}
                            >
                              <span>
                                <p className={`mb-0`}>{description}</p>
                                <p>{title}</p>
                              </span>
                              <p>
                                ${price.toFixed(2)} /{i18n.t("month")}
                              </p>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                ) : null}
                <li className={"d-flex w-100 justify-content-between"}>
                  <p>
                    {i18n.t("voPricing.Tax")} {get(billing, "taxRate", 0)}%
                  </p>
                  <p>${get(billing, "tax", 0).toFixed(2)}</p>
                </li>
              </ul>

              <div className={s.summaryColumn__pricingSection}>
                <div
                  className={`d-flex ${s.summaryColumn__pricingSection__priceItems}`}
                >
                  <span>{i18n.t("voPricing.monthlyTotal")}</span>
                  <span className={`ms-auto`}>
                    ${get(billing, "total", 0).toFixed(2)}
                  </span>
                </div>
              </div>
              <Button
                className={s.summaryColumn__summaryButton}
                onClick={next}
                variant={"outline-primary"}
                disabled={!plan?._id || !plan?.price}
              >
                <span>{i18n.t("Next")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualOfficeAddOns;
